exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-challenges-autonomous-actions-js": () => import("./../../../src/pages/company/challenges-autonomous-actions.js" /* webpackChunkName: "component---src-pages-company-challenges-autonomous-actions-js" */),
  "component---src-pages-company-challenges-compliance-to-regulations-js": () => import("./../../../src/pages/company/challenges-compliance-to-regulations.js" /* webpackChunkName: "component---src-pages-company-challenges-compliance-to-regulations-js" */),
  "component---src-pages-company-challenges-safe-and-trustworthy-js": () => import("./../../../src/pages/company/challenges-safe-and-trustworthy.js" /* webpackChunkName: "component---src-pages-company-challenges-safe-and-trustworthy-js" */),
  "component---src-pages-company-security-js": () => import("./../../../src/pages/company/security.js" /* webpackChunkName: "component---src-pages-company-security-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("./../../../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-solutions-compute-continuum-js": () => import("./../../../src/pages/solutions/compute-continuum.js" /* webpackChunkName: "component---src-pages-solutions-compute-continuum-js" */),
  "component---src-pages-solutions-contact-us-js": () => import("./../../../src/pages/solutions/contact-us.js" /* webpackChunkName: "component---src-pages-solutions-contact-us-js" */),
  "component---src-pages-solutions-data-pipeline-processor-js": () => import("./../../../src/pages/solutions/data-pipeline-processor.js" /* webpackChunkName: "component---src-pages-solutions-data-pipeline-processor-js" */),
  "component---src-pages-solutions-federated-ai-learning-js": () => import("./../../../src/pages/solutions/federated-ai-learning.js" /* webpackChunkName: "component---src-pages-solutions-federated-ai-learning-js" */),
  "component---src-pages-solutions-insight-continuum-js": () => import("./../../../src/pages/solutions/insight-continuum.js" /* webpackChunkName: "component---src-pages-solutions-insight-continuum-js" */),
  "component---src-pages-solutions-open-source-ai-platform-js": () => import("./../../../src/pages/solutions/open-source-ai-platform.js" /* webpackChunkName: "component---src-pages-solutions-open-source-ai-platform-js" */),
  "component---src-pages-solutions-virtual-iot-simulation-js": () => import("./../../../src/pages/solutions/virtual-iot-simulation.js" /* webpackChunkName: "component---src-pages-solutions-virtual-iot-simulation-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

